.gooten-components-container .products-grid-container .products-grid-lending-container .product-landing {
  border: 1px solid #dedede;
}

.gooten-components-container .products-grid-container .products-grid-lending-container .lending-footer {
  border: 1px solid #dedede;
  border-top: none;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .products-grid-container .products-grid-lending-container {
    width: 42vw;
    height: 42vw;
  }

  .gooten-components-container .products-grid-container .products-grid-lending-container .product-landing {
    border-bottom: none;
    height: 42vw;
  }

  .gooten-components-container .products-grid-container .products-grid-lending-container .lending-footer {
    height: 68px;
  }
}