.gooten-components-container .component-section {
  display: table;
  clear: both;
}

.gooten-components-container .component-section .base-text-style,
.gooten-components-container .component-section .title,
.gooten-components-container .component-section .content,
.gooten-components-container .component-section .learn-more {
  text-align: left;
}

.gooten-components-container .component-section .title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 16px;
}

.gooten-components-container .component-section .content {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
}