.gooten-components-container .options-panel-container {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .gooten-components-container .options-panel-container {
    margin-bottom: 140px;
  }
}

.gooten-components-container .options-panel-container .options-title {
  margin-top: 30px;
  margin-bottom: -14px;
}

.gooten-components-container .options-panel-container hr {
  padding-bottom: 30px;
}