.gooten-components-container h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 50px;
  line-height: 1.6;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h2 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h3 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h4,
.gooten-components-container .section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .sub-section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.76;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .bold-content {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: #2c2229;
  letter-spacing: 0px;
}

.gooten-components-container .body-content {
  font-family: "Avenir-Light",sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 15px;
  color: #2c2229;
  text-transform: inherit;
  letter-spacing: 0px;
}

.gooten-components-container .meta-content {
  font-family: "Avenir-Light",sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2229;
}

.gooten-components-container .link-default {
  text-decoration: none;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .link-default:hover,
.gooten-components-container .link-default:active {
  text-decoration: none;
  background-color: #62c3b1;
  color: #42303a;
}

.gooten-components-container .link-default:visited,
.gooten-components-container .link-default:focus {
  text-decoration: none;
}

.gooten-components-container .side-panel-container {
  margin-bottom: 170px;
}

.gooten-components-container .side-panel-container .disabled {
  opacity: 0.5;
}

.gooten-components-container .side-panel-container .title {
  font-size: 18px;
  font-weight: 900;
}

.gooten-components-container .side-panel-container .subtitle {
  font-size: 16px;
  font-weight: 900;
  padding-left: 0px;
  margin-top: 10px;
}

.gooten-components-container .side-panel-container .desc {
  display: block;
  font-size: 12px;
  color: #9d9aa0;
  margin-top: 5px;
}

.gooten-components-container .side-panel-container .link-button .btn {
  font-size: 12px;
  margin-left: 0px;
}

.gooten-components-container .side-panel-container .link-button .btn .icon-upload-button {
  transform: rotate(180deg);
}