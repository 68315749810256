.gooten-components-container .terms-section-container {
  display: block;
  margin: 0 32px 32px;
}

.gooten-components-container .terms-section-container p {
  color: #9d9aa0;
  font-size: 13px;
  margin-bottom: 6px;
}

.gooten-components-container .terms-section-container a.terms-of-service-link {
  color: inherit;
  text-decoration: inherit;
  padding-bottom: 2px;
  border-bottom: 1px solid #42303a;
}