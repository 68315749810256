.gooten-components-container .component-size-select .size-input {
  margin-top: 10px;
}

.gooten-components-container .component-size-select span {
  font-size: 13px;
  font-weight: 900;
  color: #9d9aa0;
}

.gooten-components-container .component-size-select .size-delimiter {
  line-height: 100px;
  padding: 0;
}

.gooten-components-container .component-size-select .help-block {
  position: absolute;
  top: -20px;
}