.gooten-components-container .autocomplete-container .remove-btn-container {
  width: 100%;
  text-align: right;
  margin: 10px 0px 20px;
}

.gooten-components-container .autocomplete-container .remove-btn-container a {
  color: #42303a;
}

.gooten-components-container .autocomplete-container .form-container {
  position: relative;
}

.gooten-components-container .autocomplete-container .form-container .submit-btn:focus {
  outline: none;
}

.gooten-components-container .autocomplete-container .form-container .bootstrap-typeahead-menu {
  width: 100%;
  border-radius: 0px;
  margin-top: -2px;
}

.gooten-components-container .autocomplete-container .form-container button.submit-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  z-index: 10;
  height: 100%;
}

.gooten-components-container .autocomplete-container .form-container button.submit-btn:hover {
  background-color: transparent;
}

.gooten-components-container .autocomplete-container .selected-items {
  padding: 10px 0px;
  text-align: left;
}

.gooten-components-container .autocomplete-container .selected-items .label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 110px;
  max-width: 100%;
  font-size: 14px;
  background-color: #62c3b1;
  border-radius: 0px;
  margin-right: 10px;
  display: inline-block;
  line-height: 32px;
  text-align: left;
  position: relative;
  padding-right: 25px;
  margin-bottom: 10px;
}

.gooten-components-container .autocomplete-container .selected-items .label span {
  font-size: 24px;
  position: absolute;
  right: 0px;
  top: 6px;
  cursor: pointer;
}

.gooten-components-container .autocomplete-container .rbt {
  outline: none;
}

.gooten-components-container .autocomplete-container .rbt input.rbt-input-main {
  padding-right: 40px;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1rem;
  color: #646b72;
  height: 3rem;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  padding: 1.25rem 2.5rem 0.25rem 1rem;
  position: relative;
}

.gooten-components-container .autocomplete-container .rbt input.rbt-input-main:focus {
  border: 1px solid #092dc5;
}

.gooten-components-container .autocomplete-container .rbt input.rbt-input-hint {
  height: 48px;
  width: 100%;
}

.gooten-components-container .autocomplete-container .rbt input:focus+.label {
  color: green;
}

.gooten-components-container .autocomplete-container ul.rbt-menu.dropdown-menu {
  border-radius: 6px;
  z-index: 100;
  outline: none;
}