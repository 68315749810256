.gooten-components-container .panel-layout-container.no-status-bar .main-container,
.gooten-components-container .panel-layout-container.no-status-bar .panel-container {
  top: 0;
}

.gooten-components-container .panel-layout-container .main-container {
  width: 50%;
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .gooten-components-container .panel-layout-container .main-container {
    top: 56px;
  }
}

@media (max-width: 992px) {
  .gooten-components-container .panel-layout-container .main-container {
    width: 100%;
    display: none;
  }
}

.gooten-components-container .panel-layout-container .main-container .main-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: -50px;
  padding-right: 50px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.gooten-components-container .panel-layout-container .panel-container {
  background: #f9fafb;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  width: 50%;
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0px;
  z-index: 101;
}

@media (min-width: 768px) {
  .gooten-components-container .panel-layout-container .panel-container {
    top: 56px;
  }
}

@media (max-width: 992px) {
  .gooten-components-container .panel-layout-container .panel-container {
    left: 40px;
    width: auto;
  }
}

.gooten-components-container .panel-layout-container .panel-container .panel-content {
  padding: 1.875rem 2.25rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 480px) {
  .gooten-components-container .panel-layout-container .panel-container .panel-content {
    padding: 15px 15px 0px 30px;
  }
}

.gooten-components-container .panel-layout-container .panel-container .btn-toggle {
  border: 1px solid #dedede;
  background: #f8f8f8;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: -40px;
  top: 30px;
}

.gooten-components-container .panel-layout-container .panel-container>.buttons-container {
  position: absolute;
  width: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  padding: 1rem;
  background: rgba(248,248,248,0.9);
  z-index: 102;
}

.gooten-components-container .panel-layout-container .panel-container>.buttons-container>button.button-primary,
.gooten-components-container .panel-layout-container .panel-container>.buttons-container>button.button-cta {
  margin-left: 15px;
  float: right;
}

@media (max-width: 767px) {
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container>button.button-primary,
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container>button.button-cta {
    float: inherit;
    width: 100%;
  }
}

@media (min-width: 480px) {
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container>button .hidden-xs {
    display: inline-block !important;
  }
}

@media (max-width: 480px) {
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container {
    left: 30px;
    right: 15px;
    padding: 0 0 1rem;
    border-top: 1px solid #dedede;
  }
}

@media (max-width: 767px) {
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container button,
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container .cancel-button {
    display: block;
    width: 100%;
  }

  .gooten-components-container .panel-layout-container .panel-container>.buttons-container button.button-primary,
  .gooten-components-container .panel-layout-container .panel-container>.buttons-container button.button-cta {
    margin-left: 0px;
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .gooten-components-container .panel-layout-container .panel-container>.buttons-container .cancel-button {
    margin-top: 15px;
  }
}

.gooten-components-container .panel-layout-container.collapsed .main-container {
  width: 100%;
  display: block;
}

.gooten-components-container .panel-layout-container.collapsed .panel-container {
  padding: 9px;
  width: auto;
  left: auto;
}