.gooten-components-container .settings-layout-container .setting-nav-bar {
  position: absolute;
  z-index: 4;
  width: 100%;
  background: #fff;
}

.gooten-components-container .settings-layout-container .custom-background {
  background-color: #f8f8f8;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.gooten-components-container .settings-layout-container .content {
  background-color: #ffffff;
  padding-top: 40px;
}

.gooten-components-container .settings-layout-container a.settings-link {
  color: inherit;
  text-decoration: none !important;
  border-bottom: 1px solid #62c3b1;
  cursor: pointer;
}

.gooten-components-container .settings-layout-container .text-input-container.form-group {
  margin-bottom: 32px;
}

.gooten-components-container .settings-layout-container .text-input-container .allow-overlap {
  padding-right: 20px;
}

.gooten-components-container .settings-layout-container .text-input-container.has-error .help-block {
  color: #f1643a;
  font-size: 12px;
}

.gooten-components-container .settings-layout-container .text-input-container.has-error .form-control {
  border-color: #f1643a;
}

.gooten-components-container .settings-layout-container p,
.gooten-components-container .settings-layout-container .paragraph {
  color: #42303a;
}

.gooten-components-container .settings-layout-container .cancel-btn {
  margin-left: 20px;
}

.gooten-components-container .settings-layout-container .hide-label label {
  display: none;
}

.gooten-components-container .settings-layout-container h5 {
  font-weight: 600;
  font-size: 15px;
}

.gooten-components-container .settings-layout-container .btn {
  padding: 8px 20px;
}

.gooten-components-container .settings-layout-container .email-img-missing {
  height: 80px;
  line-height: 80px;
  width: 234px;
  border: 1px solid #dedede;
  background: #f8f8f8;
  font-size: 12px;
  color: #9d9aa0;
}

.gooten-components-container .settings-layout-container .item-line-row .item-block {
  line-height: 40px;
}

.gooten-components-container .settings-layout-container .item-line-row .remove-btn-on-hover {
  display: none;
}

.gooten-components-container .settings-layout-container .item-line-row:hover .remove-btn-on-hover {
  display: block;
}

.gooten-components-container .settings-layout-container .input-note {
  display: flex;
  align-items: center;
}

.gooten-components-container .settings-layout-container .input-note i {
  width: 16px;
  margin-right: 6px;
}

.gooten-components-container .settings-layout-container .panel.panel-default .panel-body {
  background-color: #dedede;
}

.gooten-components-container .settings-layout-container .table-hover>tbody>tr:hover>td,
.gooten-components-container .settings-layout-container .table-hover>tbody>tr:hover>th {
  background-color: #d0eae7;
  border-color: #d0eae7;
}

.gooten-components-container .settings-layout-container .radio-button .indicator {
  border-radius: 10px;
}

.gooten-components-container .settings-layout-container .radio-button .indicator.active {
  border: 5px solid #62c3b1;
}

.gooten-components-container .settings-layout-container .radio-button .indicator.active .checkmark.icon-checkbox-selected {
  display: none;
}

.gooten-components-container .settings-layout-container .reciept-modal-container .modal-body {
  padding: 35px;
}

.gooten-components-container .settings-layout-container .reciept-modal-container img {
  margin-bottom: 20px;
}

.gooten-components-container .settings-layout-container .reciept-modal-container p {
  font-size: 14px;
  margin-bottom: 0px;
}

.gooten-components-container .settings-layout-container .reciept-modal-container table {
  text-align: left;
  font-size: 14px;
  width: 100%;
}

.gooten-components-container .settings-layout-container .reciept-modal-container table .table-caption {
  color: #42303a;
  font-weight: 1000;
}

.gooten-components-container .settings-layout-container .reciept-modal-container table .table-row-devider {
  height: 0px;
  padding: 3px 0 2px;
  border-bottom: 1px solid #dedede;
}

.gooten-components-container .settings-layout-container .color-gray {
  color: #9d9aa0;
  fill: #9d9aa0;
}

.gooten-components-container .settings-layout-container .color-success {
  color: #62c3b1;
}

.gooten-components-container .settings-layout-container .color-error {
  color: #a94442;
}

.gooten-components-container .settings-layout-container .user-remove-container .icon-disabled {
  cursor: auto;
  opacity: 0.5;
}

.gooten-components-container .settings-layout-container .user-remove-container .user-remove {
  position: relative;
  display: inline-block;
}

.gooten-components-container .settings-layout-container .user-remove-container .close-icon {
  left: 0;
  top: 6px;
  position: absolute;
  display: none;
  cursor: pointer;
}

.gooten-components-container .settings-layout-container .user-remove-container:hover .avatar-icon,
.gooten-components-container .settings-layout-container .user-remove-container:hover .user-icon {
  opacity: 0.5;
}

.gooten-components-container .settings-layout-container .user-remove-container:hover .close-icon {
  display: inline-block;
}

.gooten-components-container .settings-layout-container .user-remove-container:hover .icon-transparent {
  opacity: 0;
}

@media (max-width: 480px) {
  .gooten-components-container .settings-layout-container .user-remove-container:hover .avatar-icon,
  .gooten-components-container .settings-layout-container .user-remove-container:hover .user-icon {
    opacity: 0;
  }

  .gooten-components-container .settings-layout-container .user-remove-container .avatar-icon,
  .gooten-components-container .settings-layout-container .user-remove-container .user-icon {
    opacity: 0;
  }

  .gooten-components-container .settings-layout-container .user-remove-container .close-icon {
    display: inline-block;
  }
}

.gooten-components-container .settings-layout-container .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gooten-components-container .settings-layout-container .icon-transparent {
  opacity: 0;
}

@media only screen and (min-width: 1200px) {
  .gooten-components-container .settings-layout-container .team-access-container {
    padding: 0px;
  }

  .gooten-components-container .settings-layout-container .team-access-container .dropdown.multiselect-component {
    display: inline-block;
  }
}

@media only screen and (min-width: 992px) {
  .gooten-components-container .settings-layout-container .team-access-container .dropdown.multiselect-component {
    display: inline-block;
  }

  .gooten-components-container .settings-layout-container .fixed-lg .dropdown.multiselect-component {
    width: 160px;
  }
}

@media screen and (min-width: 768px) {
  .gooten-components-container .settings-layout-container .fixed-column {
    width: 36px;
  }
}