.gooten-components-container .product-item {
  text-align: center;
  vertical-align: top;
  margin-top: 1px;
}

.gooten-components-container .product-item .product-content {
  border: 1px solid #dedede;
  margin: 6px;
  padding: 8px;
  cursor: pointer;
}

.gooten-components-container .product-item .product-content .checkbox-squared {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  cursor: pointer;
}

.gooten-components-container .product-item .product-content .checkbox-squared .indicator {
  margin: 15px 0 0 15px;
}

.gooten-components-container .product-item .product-content .main-title {
  position: absolute;
  top: 7px;
  right: 7px;
  background: #fccf2b;
  padding: 4px 8px 0px 10px;
  z-index: 900;
}

.gooten-components-container .product-item .product-content .product-image {
  width: 100%;
  height: 0;
  padding-bottom: 99.6%;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s;
}

.gooten-components-container .product-item .product-content .product-image.image-loaded {
  opacity: 1;
}

.gooten-components-container .product-item .product-content .product-image.edit-mode {
  z-index: -1;
}