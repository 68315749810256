.gooten-components-container .reference-card-container ol.ref-list {
  padding: 0 0 0 30px;
  width: 100%;
}

.gooten-components-container .reference-card-container ol.ref-list li {
  background: url(assets/triangle.png) 15px 5px no-repeat;
  cursor: pointer;
  display: block;
  padding-left: 30px;
  position: relative;
  margin-left: -45px;
  list-style: none;
}

.gooten-components-container .reference-card-container ol.ref-list li a {
  margin-left: 15px;
  line-height: 2.27;
  text-decoration: none;
}

.gooten-components-container .reference-card-container ol.ref-list li a {
  text-decoration: none;
  border-bottom: none;
}