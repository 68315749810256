.gooten-components-container .component-slider {
  overflow: hidden;
  position: relative;
}

.gooten-components-container .component-slider .center {
  margin: 0 auto;
}

.gooten-components-container .component-slider .slider-content {
  z-index: 50;
  margin-bottom: 40px;
}

.gooten-components-container .component-slider .slider-veritical-controls-container {
  position: absolute;
  height: 100%;
  margin-bottom: 40px;
  z-index: 100;
  text-align: right;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .big-icon.empty {
  color: transparent;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item {
  width: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item .item-name {
  display: none;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item .big-icon {
  padding-bottom: 1px;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item.show-names {
  width: 80px;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item.show-names .item-name {
  display: block;
  margin-right: 20px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gooten-components-container .component-slider .slider-veritical-controls-container .slide-item.show-names .big-icon {
  float: right;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-top: 40px;
  z-index: 100;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .item-name {
  display: none;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .big-icon {
  cursor: pointer;
  display: inline-block;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .big-icon.empty {
  color: transparent;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .slide-item {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .slide-item .big-icon {
  cursor: pointer;
  vertical-align: bottom;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .sc-slide-container {
  white-space: nowrap;
  display: inline-block;
}

.gooten-components-container .component-slider .slider-horizontal-controls-container .sc-slide-container .sc-slide-content {
  display: inline-block;
}

.gooten-components-container .component-slider .change-enter {
  opacity: 0.01;
}

.gooten-components-container .component-slider .change-enter.change-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.gooten-components-container .component-slider .change-leave {
  opacity: 1;
}

.gooten-components-container .component-slider .change-leave.change-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.gooten-components-container .component-slider .slide-left-enter {
  transform: translate(-100%);
}

.gooten-components-container .component-slider .slide-left-enter.slide-left-enter-active {
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-left-leave.slide-left-leave-active {
  transform: translate(100%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-right-enter {
  transform: translate(100%);
}

.gooten-components-container .component-slider .slide-right-enter.slide-right-enter-active {
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-right-leave {
  transform: translate(0%);
}

.gooten-components-container .component-slider .slide-right-leave.slide-right-leave-active {
  transform: translate(-100%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-up-enter {
  transform: translate(0%, -100%);
}

.gooten-components-container .component-slider .slide-up-enter.slide-up-enter-active {
  transform: translate(0%, 0%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-up-leave.slide-up-leave-active {
  transform: translate(0%, 100%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-down-enter {
  transform: translate(0%, 100%);
}

.gooten-components-container .component-slider .slide-down-enter.slide-down-enter-active {
  transform: translate(0%, 0%);
  transition: transform 1000ms ease-in-out;
}

.gooten-components-container .component-slider .slide-down-leave {
  transform: translate(0%, 0%);
}

.gooten-components-container .component-slider .slide-down-leave.slide-down-leave-active {
  transform: translate(0%, -100%);
  transition: transform 1000ms ease-in-out;
}