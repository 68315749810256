.gooten-components-container .image-upload-container.normal .panel-layout-container .main-container {
  width: 58%;
}

.gooten-components-container .image-upload-container.normal .panel-layout-container .panel-container {
  width: 42%;
}

@media (max-width: 991px) {
  .gooten-components-container .image-upload-container.normal .panel-layout-container .panel-container {
    width: auto;
  }
}

.gooten-components-container .image-upload-container .tooltip-top {
  z-index: 9999;
}

.gooten-components-container .image-upload-container .copy-message {
  position: fixed;
  left: 42%;
  top: 30px;
}

.gooten-components-container .image-upload-container .editor-panel {
  width: 100%;
  padding: 0 15px;
  position: relative;
  text-align: center;
}

.gooten-components-container .image-upload-container .editor-panel.expanded .image-editor-mode-container {
  position: absolute;
  top: 30px;
  right: 100px;
}

@media (max-width: 767px) {
  .gooten-components-container .image-upload-container .editor-panel.expanded .image-editor-mode-container {
    position: static;
  }
}

.gooten-components-container .image-upload-container .editor-panel.expanded .product-section-container {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media (max-width: 991px) {
  .gooten-components-container .image-upload-container .editor-panel.expanded .product-section-container {
    display: none;
  }
}

.gooten-components-container .image-upload-container .editor-panel.expanded .product-section-container h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gooten-components-container .image-upload-container .editor-panel .component-pager {
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.gooten-components-container .image-upload-container .editor-panel .component-pager button {
  margin-top: 0.25rem;
}