.gooten-components-container .component-colors-preview .color-item,
.gooten-components-container .component-colors-preview .two-color-item {
  float: left;
  width: 26px;
  height: 26px;
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-right: 14px;
  margin-bottom: 14px;
}

.gooten-components-container .component-colors-preview .two-color-item .color-one,
.gooten-components-container .component-colors-preview .two-color-item .color-two {
  content: '';
  width: 13px;
}

.gooten-components-container .component-colors-preview .two-color-item .color-one {
  float: left;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gooten-components-container .component-colors-preview .two-color-item .color-two {
  float: right;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.gooten-components-container .component-colors-preview .base-text-style,
.gooten-components-container .component-colors-preview .color-item-text {
  text-align: left;
}

.gooten-components-container .component-colors-preview .color-item-text {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
}