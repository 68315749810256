.gooten-components-container .image-editor-container {
  width: 100%;
}

.gooten-components-container .image-editor-container .editor-placeholder {
  margin: 10px auto 5px;
  min-width: auto;
}

.gooten-components-container .image-editor-container .editor-placeholder .toolbar {
  width: 320px;
}

.gooten-components-container .image-editor-container .editor-placeholder .toolbar .editor-btn {
  width: 15%;
}

.gooten-components-container .image-editor-container .editor-placeholder.maximized .loading-spinner {
  left: 49%;
  bottom: 47%;
}

.gooten-components-container .image-editor-container .editor-placeholder.mobile .toolbar {
  height: 100px;
}

.gooten-components-container .image-editor-container .editor-placeholder.mobile .toolbar .editor-btn {
  width: 15%;
  height: 60px;
}