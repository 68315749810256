.gooten-components-container .woo-connect-container {
  padding-top: 40px;
}

.gooten-components-container .woo-connect-container .border-block-img-woo-container {
  margin-top: 10px;
  justify-content: center;
  height: 564px;
}

.gooten-components-container .woo-connect-container .border-block-img-woo-container .border-block-img {
  width: 100%;
  background: url("./assets/woocommerce-connect-graphic.svg") top center no-repeat;
}

@media screen and (max-width: 479px) {
  .gooten-components-container .woo-connect-container .border-block-img-woo-container {
    margin-top: 40px;
  }

  .gooten-components-container .woo-connect-container .border-block-img-woo-container .border-block-img {
    background: url("./assets/woocommerce-connect-graphic.svg") top left no-repeat;
  }
}