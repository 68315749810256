.gooten-components-container .apparel-panel-container .items-grid {
  display: flex;
  flex-wrap: wrap;
}

.gooten-components-container .apparel-panel-container .search-box {
  width: 30%;
  margin: 10px;
}

.gooten-components-container .apparel-panel-container .search-box svg {
  top: 0rem;
}

.gooten-components-container .apparel-panel-container .search-box .input.seach-field {
  height: 3rem;
}

.gooten-components-container .apparel-panel-container .search-box .input.seach-field .input-container {
  top: 0rem;
}

.gooten-components-container .apparel-panel-container .select-container {
  top: 0.75rem;
}

.gooten-components-container .apparel-panel-container>.buttons-container {
  position: fixed;
  width: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  padding: 1rem;
  z-index: 102;
}

.gooten-components-container .apparel-panel-container>.buttons-container>button.button-primary,
.gooten-components-container .apparel-panel-container>.buttons-container>button.button-cta {
  margin-left: 15px;
  float: right;
}

@media (max-width: 767px) {
  .gooten-components-container .apparel-panel-container>.buttons-container>button.button-primary,
  .gooten-components-container .apparel-panel-container>.buttons-container>button.button-cta {
    float: inherit;
    width: 100%;
  }
}

@media (min-width: 480px) {
  .gooten-components-container .apparel-panel-container>.buttons-container>button .hidden-xs {
    display: inline-block !important;
  }
}

@media (max-width: 480px) {
  .gooten-components-container .apparel-panel-container>.buttons-container {
    left: 30px;
    right: 15px;
    padding: 0 0 1rem;
    border-top: 1px solid #dedede;
  }
}

@media (max-width: 767px) {
  .gooten-components-container .apparel-panel-container>.buttons-container button,
  .gooten-components-container .apparel-panel-container>.buttons-container .cancel-button {
    display: block;
    width: 100%;
  }

  .gooten-components-container .apparel-panel-container>.buttons-container button.button-primary,
  .gooten-components-container .apparel-panel-container>.buttons-container button.button-cta {
    margin-left: 0px;
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .gooten-components-container .apparel-panel-container>.buttons-container .cancel-button {
    margin-top: 15px;
  }
}