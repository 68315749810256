.gooten-components-container .collapse-container .d-flex {
  align-items: center;
}

.gooten-components-container .collapse-container.display-bottom-border {
  border-bottom: 1px solid #dedede;
}

.gooten-components-container .collapse-container .title {
  position: relative;
  font-weight: lighter;
  align-items: center;
  justify-content: space-between;
}

.gooten-components-container .collapse-container .title.clickable {
  cursor: pointer;
}

.gooten-components-container .collapse-container .title .show-hide-container {
  padding-top: 3px;
  float: right;
}

.gooten-components-container .collapse-container .title .collapse-icon {
  font-size: 14px;
  color: #62c3b1;
}

.gooten-components-container .collapse-container .title-closed-view {
  color: #ada5aa;
}

.gooten-components-container .collapse-container h3.title {
  font-size: 21px;
  padding-bottom: 7px;
  margin-bottom: 30px;
}

.gooten-components-container .collapse-container h3.title .show-hide-link {
  font-size: 15px;
  right: 40px;
  color: #62c3b1;
  line-height: 40px;
}

.gooten-components-container .collapse-container h3.title .collapse-icon {
  margin-right: 10px;
  margin-left: 16px;
  line-height: 36px;
}

.gooten-components-container .collapse-container h4.title {
  margin-bottom: 30px;
}

.gooten-components-container .collapse-container h4.title .change-link {
  font-size: 15px;
  right: 40px;
  color: #62c3b1;
  line-height: 40px;
}

.gooten-components-container .collapse-container h4.title .collapse-icon {
  margin-right: 30px;
  margin-left: 16px;
  line-height: 25px;
  color: #42303a;
}

.gooten-components-container .collapse-container h5.title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 17px;
  padding: 0.5rem;
}

.gooten-components-container .collapse-container h5.title .change-link {
  font-size: 25px;
  right: 40px;
  color: #62c3b1;
  line-height: 40px;
}

.gooten-components-container .collapse-container h5.title .collapse-icon {
  margin-right: 30px;
  margin-left: 16px;
  line-height: 0.75em;
  font-size: 10px;
  color: #42303a;
}

.gooten-components-container .collapse-container h5.title .collapse-icon-closed {
  margin-right: 30px;
  margin-left: 16px;
  line-height: 0.75em;
  font-size: 10px;
  color: #ada5aa;
}

.gooten-components-container .collapse-container .checkbox-filled {
  background: url(../../MainView/assets/checkbox_filled@2x.png) 0 0px no-repeat;
  background-size: 28px 28px;
}

.gooten-components-container .collapse-container .checkbox-empty {
  background: url(../../MainView/assets/checkbox_empty@2x.png) 0 0px no-repeat;
  background-size: 28px 28px;
}

.gooten-components-container .collapse-container .panel.panel-default {
  margin-left: 2px;
  background-color: #f3f3f3;
}

.gooten-components-container .collapse-container .collapse-content-container .collapse-content {
  padding-left: 10px;
  padding-top: 10px;
}

.gooten-components-container .collapse-container .collapse-content-container .collapse-closed {
  padding: 0 30px 30px;
}

.gooten-components-container .collapse-container .slide-down-enter {
  max-height: 0;
  overflow: hidden;
}

.gooten-components-container .collapse-container .slide-down-enter.slide-down-enter-active {
  max-height: 700px;
  overflow: hidden;
  transition: max-height 300ms ease-in;
}

.gooten-components-container .collapse-container .slide-down-leave {
  max-height: 700px;
  overflow: hidden;
}

.gooten-components-container .collapse-container .slide-down-leave.slide-down-leave-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}