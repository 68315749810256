.gooten-components-container .image-selection-upload-device {
  border: 1px dashed #dedfe1;
}

.gooten-components-container .image-selection-upload-device .bcg-image {
  background: url("../../../assets/modal_bcg.png") center center/contain no-repeat;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .image-selection-upload-device {
    border: none;
    width: 100%;
    margin: 0;
  }
}