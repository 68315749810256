.gooten-components-container .apparel-panel-item {
  display: flex;
  width: 10%;
  margin: 10px;
  flex-direction: column;
}

.gooten-components-container .apparel-panel-item img {
  width: 100%;
  height: auto;
}