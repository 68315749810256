.gooten-components-container .product-selection-container .step-outer svg circle {
  fill: #092dc5;
}

.gooten-components-container .product-selection-container span.cancel {
  border-bottom: 2px solid #092dc5;
}

.gooten-components-container .product-selection-container .panel {
  background-color: #f9fafb;
  border: none;
}

.gooten-components-container .product-selection-container .panel .panel-body {
  min-height: 300px;
  padding: 20px 40px 20px 40px;
  border-radius: 4px;
}

.gooten-components-container .product-selection-container .panel .panel-body .row {
  min-height: 58px;
}

.gooten-components-container .product-selection-container .step-index {
  background-color: #dedede;
}

.gooten-components-container .product-selection-container .step-index.active {
  background-color: #092dc5;
}

.gooten-components-container .product-selection-container .clear-link {
  cursor: pointer;
  color: #092dc5;
}

.gooten-components-container .product-selection-container .clear-link:hover {
  color: #42303a;
}