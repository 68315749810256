.gooten-components-container .address-selector {
  display: block;
  padding: 2rem 0;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: -1px;
}

.gooten-components-container .address-selector .saved-addresses {
  margin-bottom: 10px;
}

.gooten-components-container .address-selector .saved-addresses .pager {
  margin-top: 0;
}

.gooten-components-container .address-selector .saved-addresses .pager .pager-text {
  color: #9d9aa0;
  font-weight: bold;
}

.gooten-components-container .address-selector .saved-addresses .pager .btn-no-border {
  background: none;
  border: none;
  line-height: 1;
  text-transform: none;
  padding: 10px;
  color: #62c3b1;
}

.gooten-components-container .address-selector .saved-addresses .pager .btn-no-border:disabled {
  color: #9d9aa0;
  cursor: not-allowed;
}

.gooten-components-container .address-selector .saved-addresses .btn-use-different-address {
  background: none;
  border: none;
  font-weight: bold;
  line-height: 1;
  text-transform: none;
  padding: 10px 0;
}

.gooten-components-container .address-selector .saved-addresses .btn-use-different-address .icon-add {
  font-size: 20px;
  margin-right: 16px;
}

.gooten-components-container .address-selector .new-address {
  overflow: hidden;
}

.gooten-components-container .address-selector .button-save {
  float: right;
  min-width: 230px;
}