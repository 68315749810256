.gooten-components-container .onboarding-dashboard-view-container {
  color: #422f39;
}

@media (max-width: 480px) {
  .gooten-components-container .onboarding-dashboard-view-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.gooten-components-container .onboarding-dashboard-view-container p {
  font-size: 15px;
  color: #422f39;
}

.gooten-components-container .onboarding-dashboard-view-container .flex-container-row,
.gooten-components-container .onboarding-dashboard-view-container .flex-container-column,
.gooten-components-container .onboarding-dashboard-view-container .left-flex-container,
.gooten-components-container .onboarding-dashboard-view-container .right-flex-container {
  display: flex;
  flex-direction: column;
}

.gooten-components-container .onboarding-dashboard-view-container .flex-container-row {
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .flex-container-form {
  align-items: flex-end;
}

.gooten-components-container .onboarding-dashboard-view-container .flex-container-column {
  flex-wrap: nowrap;
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .left-flex-container {
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .right-flex-container {
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .card {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2em;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.gooten-components-container .onboarding-dashboard-view-container .card .card-content {
  margin: 0.5em 0.5em 0 0.5em;
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .card .card-content .card-title {
  font-family: Avenir-Heavy;
  font-size: 21px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #42303a;
}

.gooten-components-container .onboarding-dashboard-view-container .card .card-content .card-list-container {
  border-top: 2px solid #eaeaea;
  padding-top: 0.75em;
  margin-top: 0.5em;
  width: 100%;
}

.gooten-components-container .onboarding-dashboard-view-container .card .card-content .card-hr-line {
  height: 3px;
  border: solid 1px #dedede;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img {
  margin: 0 0 0 30px;
  display: none;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img .img-responsive {
  height: 115px;
  width: 115px;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img .img-responsive.img-sloth-product {
  background: url("./assets/sloth_1@2x.png") center center/contain no-repeat;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img .img-responsive.img-sloth-package {
  background: url("./assets/sloth_2@2x.png") center center/contain no-repeat;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img .img-responsive.img-sloth-link {
  background: url("./assets/sloth_3@2x.png") center center/contain no-repeat;
}

.gooten-components-container .onboarding-dashboard-view-container .border-block-img .img-responsive.img-sloth-connect {
  background: url("./assets/sloth_4@2x.png") center center/contain no-repeat;
}

.gooten-components-container .onboarding-dashboard-view-container .text-input-container .input-container .allow-overlap {
  padding-left: 3px;
  padding-right: 3px;
}

.gooten-components-container .onboarding-dashboard-view-container .right-flex-container>.card:last-child {
  margin-bottom: 90px;
}

.gooten-components-container .onboarding-dashboard-view-container .left-flex-container>.card:last-child {
  margin-bottom: 30px;
}

@media only screen and (max-width: 850px) {
  .gooten-components-container .onboarding-dashboard-view-container .img-responsive {
    display: none;
  }
}

@media (min-width: 992px) {
  .gooten-components-container .onboarding-dashboard-view-container .flex-container {
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .gooten-components-container .onboarding-dashboard-view-container .flex-container-row {
    flex-wrap: nowrap;
  }

  .gooten-components-container .onboarding-dashboard-view-container .left-flex-container {
    width: 64%;
  }

  .gooten-components-container .onboarding-dashboard-view-container .right-flex-container {
    width: 32%;
  }

  .gooten-components-container .onboarding-dashboard-view-container .left-flex-container>.card:last-child {
    margin-bottom: 90px;
  }

  .gooten-components-container .onboarding-dashboard-view-container .right-flex-container>.card:last-child {
    margin-bottom: 0;
  }

  .gooten-components-container .onboarding-dashboard-view-container .border-block-img {
    display: block;
  }
}

.gooten-components-container .onboarding-dashboard-view-container .olm-video-container {
  max-width: 100%;
  margin-bottom: 40px;
}

.gooten-components-container .onboarding-dashboard-view-container .olm-video-container iframe {
  display: block;
  max-width: 100%;
}