.gooten-components-container .sign-up .top-content {
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 11px;
}

.gooten-components-container .sign-up .top-content img {
  margin: 18px 0 0 32px;
}

.gooten-components-container .sign-up .top-content .has-existing-account {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 18px 32px 0 32px;
  letter-spacing: 1px;
}

.gooten-components-container .sign-up .top-content .has-existing-account .already-registered {
  color: #646b72;
  white-space: nowrap;
}

.gooten-components-container .sign-up .top-content .has-existing-account .log-in {
  text-decoration: none;
  margin-left: 10px;
  color: #0a2599;
}