.gooten-components-container .nav {
  overflow: hidden;
}

.gooten-components-container .full-screen-preview-container {
  background: rgba(66,48,58,0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: scroll;
}

.gooten-components-container .full-screen-preview-container .associated-options {
  margin-top: 20px;
}

.gooten-components-container .full-screen-preview-container span {
  color: white;
  font-weight: 900;
  font-size: 15px;
}

.gooten-components-container .full-screen-preview-container span.sku-options {
  display: block;
  font-size: 14px;
  font-weight: 100;
  color: #dedede;
  padding: 16px 0 16px 0;
  white-space: pre-wrap;
}

.gooten-components-container .full-screen-preview-container span.number-of-mockups {
  color: #9d9aa0;
}

.gooten-components-container .full-screen-preview-container .left-close {
  height: 100%;
  z-index: 1000;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .left-close {
    display: none;
  }
}

.gooten-components-container .full-screen-preview-container .left-content {
  height: 70%;
  max-height: 50vw;
  margin-top: 80px;
  padding: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .left-content {
    margin-top: 45px;
    max-height: 90vw;
  }
}

.gooten-components-container .full-screen-preview-container .left-content .button-holder {
  display: flex;
  justify-content: center;
  padding: 0;
}

.gooten-components-container .full-screen-preview-container .left-content .big-icon {
  text-align: center;
  background: #fccf2b;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: rgba(66,48,58,0.95);
  font-size: 13px;
  padding: 11px 0 0 2px;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .left-content .big-icon {
    height: 25px;
    width: 25px;
    font-size: 11px;
    padding: 8px 0 0 2px;
  }
}

.gooten-components-container .full-screen-preview-container .left-content .big-icon.left {
  padding: 11px 0;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .left-content .big-icon.left {
    padding: 8px 0;
  }
}

.gooten-components-container .full-screen-preview-container .left-content .slider-content {
  height: 100%;
}

.gooten-components-container .full-screen-preview-container .left-content .slider-content .product-image {
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.gooten-components-container .full-screen-preview-container .left-content .slider-content .product-image .spinner-container {
  position: absolute;
  top: auto;
  z-index: 1;
}

.gooten-components-container .full-screen-preview-container .left-content .slider-content .product-image img {
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.gooten-components-container .full-screen-preview-container .left-content .slider-content .product-image img.image-loaded {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .left-content .slider-content .product-image {
    width: 100%;
  }
}

.gooten-components-container .full-screen-preview-container .right-content {
  margin-top: 100px;
  height: 80%;
  padding: 0 0 0 10px;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .right-content {
    margin-top: 40px;
    height: 10%;
    padding: 0;
  }
}

.gooten-components-container .full-screen-preview-container .right-close {
  height: 100%;
  z-index: 1000;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .right-close {
    height: 40px;
  }
}

.gooten-components-container .full-screen-preview-container .right-close span {
  display: block;
  margin-top: 15px;
  color: #9d9aa0;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .full-screen-preview-container .right-close span {
    position: absolute;
    right: 20px;
  }
}