.gooten-components-container .animation-slide-up-enter {
  opacity: 0;
  transform: translate(0%, 180%);
}

.gooten-components-container .animation-slide-up-enter.animation-slide-up-enter-active {
  opacity: 1;
  transform: translate(0%, 0%);
  transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
}

.gooten-components-container .animation-slide-up-leave {
  opacity: 1;
  transform: translate(0%, 0%);
}

.gooten-components-container .animation-slide-up-leave.animation-slide-up-leave-active {
  opacity: 0;
  transform: translate(0%, 180%);
  transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
}

.gooten-components-container .animation-fade-enter {
  opacity: 0;
}

.gooten-components-container .animation-fade-enter.animation-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.gooten-components-container .animation-fade-leave {
  opacity: 1;
}

.gooten-components-container .animation-fade-leave.animation-fade-leave-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.gooten-components-container .animation-quick-fade-enter {
  opacity: 0;
}

.gooten-components-container .animation-quick-fade-enter.animation-quick-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.gooten-components-container .animation-quick-fade-leave {
  opacity: 1;
}

.gooten-components-container .animation-quick-fade-leave.animation-quick-fade-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.gooten-components-container .mount-appear {
  opacity: 0.01;
}

.gooten-components-container .mount-appear.mount-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.gooten-components-container .products-container .notification-modal-container {
  top: 0;
}