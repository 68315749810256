.gooten-components-container .link-skus-selection-view-container .product-selection-container {
  padding-top: 30px;
}

.gooten-components-container .link-skus-selection-view-container a.skip-link {
  color: #62c3b1;
}

@media screen and (max-width: 991px) {
  .gooten-components-container .link-skus-selection-view-container .can-go-next-container {
    text-align: center;
    padding-right: 0px;
  }

  .gooten-components-container .link-skus-selection-view-container .container-mobile {
    padding-left: 0px;
    padding-right: 0px;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container h4 {
    text-align: center;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .variants-container {
    width: 100%;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .selected-variant-preview {
    width: 75%;
    margin-right: 0px;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .selected-variant-preview.alone {
    width: 100%;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .variants-panel {
    width: 25%;
    padding-left: 12px;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .variants-panel .scroll-item {
    width: 100%;
  }

  .gooten-components-container .link-skus-selection-view-container .variant-selection-container .variants-panel .variants-item {
    width: 100%;
    height: auto;
  }

  .gooten-components-container .link-skus-selection-view-container .product-selection-container .panel .panel-body {
    min-height: auto;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container thead tr {
    display: block;
    padding-left: 60px;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container thead tr td {
    display: inline-block;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container thead tr td.selection-column {
    float: right;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container thead tr td.selection-column .dropdown .dropdown-toggle {
    text-align: right;
    border: none;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container thead tr td.selection-column .dropdown .dropdown-menu-container {
    right: 0px;
    left: auto;
    width: 210px;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr {
    display: block;
    width: 100%;
    position: relative;
    min-height: 68px;
    padding-left: 120px;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr td {
    display: inline-block;
    border: none;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr td.selection-column {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr td.option-column {
    width: 100%;
    padding-bottom: 0px;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr td.price-column {
    margin-top: 10px;
    width: 100%;
  }

  .gooten-components-container .link-skus-selection-view-container .skus-list-container tbody tr td.price-column .price-value {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .gooten-components-container .link-skus-selection-view-container .info-line-container .col-xs-12 {
    text-align: center;
  }

  .gooten-components-container .link-skus-selection-view-container .info-line-container .col-xs-12:first-child {
    margin-top: 15px;
  }
}