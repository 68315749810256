.gooten-components-container .variants-list-container {
  text-align: left;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.gooten-components-container .variants-list-container .variants-group {
  margin-bottom: 60px;
}

.gooten-components-container .variants-list-container .variants-group .group-title {
  font-weight: 600;
}

.gooten-components-container .variants-list-container h3 {
  font-size: 21px;
  color: #2c2229;
  margin-top: 10px;
  padding-bottom: 3px;
  margin-bottom: 50px;
  border-bottom: 2px solid #dedede;
}

.gooten-components-container .variants-list-container .variants-list-container-title {
  text-align: center;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .gooten-components-container .variants-list-container {
    margin-bottom: 80px;
  }
}