.gooten-components-container .hub-product-selection-container .step-outer svg circle {
  fill: #092dc5;
}

.gooten-components-container .hub-product-selection-container span.cancel {
  border-bottom: 2px solid #092dc5;
}

.gooten-components-container .hub-product-selection-container .btn-cancel {
  border: none;
  border-bottom: 2px solid #092dc5;
}

.gooten-components-container .hub-product-selection-container .panel {
  background-color: #f2f3f7;
  border: none;
}

.gooten-components-container .hub-product-selection-container .step-index {
  background-color: #dedede;
}

.gooten-components-container .hub-product-selection-container .step-index.active {
  background-color: #092dc5;
}

.gooten-components-container .hub-product-selection-container .clear-link {
  cursor: pointer;
  color: #092dc5;
}

.gooten-components-container .hub-product-selection-container .clear-link:hover {
  color: #42303a;
}

.gooten-components-container .hub-product-selection-container hr.delimiter {
  margin-top: 10px;
  border-color: #9d9aa0;
}

.gooten-components-container .hub-product-selection-container hr.delimiter.no-margin {
  margin-top: 0;
}

.gooten-components-container .hub-product-selection-container .pill-container.active-state {
  background-color: #092dc5;
  color: white;
  border: 1px solid #092dc5;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .hub-product-selection-container .can-go-next-container {
    width: 100%;
    left: 0;
  }
}