.gooten-components-container .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  background-color: rgba(0,0,0,0.5);
  display: none;
}

.gooten-components-container .backdrop.active {
  display: block;
}