.gooten-components-container .tile-container {
  cursor: pointer;
  min-height: 270px;
}

.gooten-components-container .tile-container .thumbnail {
  border: 0;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #dedede;
}

.gooten-components-container .tile-container .thumbnail.disabled {
  background-color: #dedede;
  text-decoration: line-through;
  cursor: not-allowed;
}

.gooten-components-container .tile-container .thumbnail.active {
  border: 1px solid #3e8acc;
}