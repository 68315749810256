.gooten-components-container .sign-up {
  display: flex;
  flex-direction: column;
  height: inherit;
  background: linear-gradient(to bottom, #d3e2f8 0px, #d3e2f8 260px, #0a2599 260px, #0a2599 100%);
}

.gooten-components-container .sign-up .button-area {
  display: flex;
  justify-content: flex-end;
}

.gooten-components-container .sign-up .button-area .back-button {
  margin-right: 15px;
}

.gooten-components-container .sign-up .back-button {
  color: #373f49;
  border-radius: 4px;
  border: 0;
  background-color: #f4f4f5;
  text-transform: uppercase;
  padding: 14px 24px;
  font-weight: 800;
  font-size: 13px;
}

.gooten-components-container .sign-up .sign-up-button {
  font-family: AvenirLTStd-Light, sans-serif;
  font-style: normal;
  text-align: center;
  font-size: 13px;
  background: #092dc5;
  border-radius: 4px;
  color: white;
  padding: 14px 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.gooten-components-container .sign-up .input-helper-message {
  padding-left: 15px;
  padding-top: 5px;
  font-size: 13px;
  letter-spacing: 0.15px;
  color: #646b72;
}

.gooten-components-container .sign-up .middle-content {
  display: flex;
  margin: 70px auto 25px auto;
  max-width: 1000px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card {
  width: 510px;
  height: fit-content;
  height: -moz-max-content;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0px 0px 18px 0px #8d8d8d6b;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .main-header {
  font-weight: 800;
  font-size: 25px;
  color: black;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .main-subheader {
  font-weight: normal;
  font-size: 13px;
  color: #646b72;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .card-section {
  display: flex;
  justify-content: space-between;
  margin: 26px 0 20px 0;
  font-weight: 800;
  font-size: 11px;
  color: #092dc5;
  letter-spacing: 1px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .card-section .progress-step {
  color: #b1b5b9;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .card-section .progress-step .current-step {
  color: #092dc5;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .sign-up-field {
  margin-bottom: 24px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .sign-up-field.button-spacing {
  margin-bottom: 22px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .sign-up-field.password-margin {
  margin-bottom: 12px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .sign-up-field.field-error {
  margin-bottom: 12px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .sign-up-field.field-error .error-msg {
  margin: 5px 0 0 0;
  font-size: 13px;
  letter-spacing: 0.15px;
  color: #9d0606;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .error-msg {
  margin: 5px 0 0 0;
  font-size: 13px;
  letter-spacing: 0.15px;
  color: #9d0606;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .continue-button {
  background-color: #d3e2f8;
  font-weight: 800;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 0 15px;
  min-width: 150px;
  line-height: 48px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  color: #092dc5;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .continue-button img,
.gooten-components-container .sign-up .middle-content .sign-up-card .continue-button span {
  margin-left: 5px;
}

.gooten-components-container .sign-up .middle-content .sign-up-card .continue-button img {
  transform: translate(0, -2px);
}

.gooten-components-container .sign-up.sensaria {
  background: linear-gradient(to bottom, #1D5F79 0px, #1D5F79 260px, #e7e7e7 260px, #e7e7e7 100%);
}

.gooten-components-container .sign-up.sensaria .middle-content {
  margin: 35px auto 25px auto;
}

.gooten-components-container .sign-up.sensaria .signup-logo {
  width: 100px;
}

.gooten-components-container .sign-up.sensaria .gooten-logo {
  width: 48px;
  margin: 18px 0 0 8px;
}

.gooten-components-container .sign-up.sensaria .white-x {
  color: #fff;
}

.gooten-components-container .sign-up.sensaria .sign-up-info .info-header {
  color: #A7A8A9;
}

.gooten-components-container .sign-up.sensaria .sign-up-info .checklist-item {
  color: #1D5F79;
}

.gooten-components-container .sign-up.sensaria .has-existing-account .already-registered {
  color: #fff !important;
}

.gooten-components-container .sign-up.sensaria .has-existing-account a {
  color: #e7e7e7 !important;
}

.gooten-components-container .sign-up.fujifilm {
  background: linear-gradient(to bottom, #F2F2F2 0px, #F2F2F2 260px, #000 260px, #000 100%);
}

.gooten-components-container .sign-up.fujifilm .middle-content {
  margin: 53px auto 25px auto;
}

.gooten-components-container .sign-up.fujifilm .signup-logo {
  width: 100px;
}

.gooten-components-container .sign-up.fujifilm .gooten-logo {
  width: 48px;
  margin: 18px 0 0 0;
}

.gooten-components-container .sign-up.fujifilm .white-x {
  color: #fff;
}

.gooten-components-container .sign-up.fujifilm .sign-up-info .info-header {
  color: #2c2229;
}

.gooten-components-container .sign-up.fujifilm .sign-up-info .checklist-item {
  color: #fff;
}

.gooten-components-container .sign-up.fujifilm .has-existing-account .already-registered {
  color: #2c2229 !important;
}

.gooten-components-container .sign-up.fujifilm .has-existing-account a {
  color: #565656 !important;
}

@media (max-width: 992px) {
  .gooten-components-container .sign-up .middle-content .sign-up-info {
    display: none;
  }
}

@media (max-width: 768px) {
  .gooten-components-container .sign-up .middle-content {
    margin: 36px 24px;
  }

  .gooten-components-container .sign-up .middle-content .button-area.space-between {
    justify-content: space-between;
  }

  .gooten-components-container .sign-up .middle-content .sign-up-button {
    flex: 1;
  }

  .gooten-components-container .sign-up .middle-content .sign-up-card {
    width: 100%;
    padding: 16px;
  }

  .gooten-components-container .sign-up .middle-content .sign-up-card .continue-button {
    width: 100%;
  }
}