.gooten-components-container .component-background-select {
  margin-bottom: 30px;
}

.gooten-components-container .component-background-select .subtitle-desc {
  font-weight: 900;
  padding-left: 0px;
  margin-top: 10px;
  display: block;
  font-size: 13px;
  color: #9d9aa0;
  margin-top: 5px;
}

.gooten-components-container .component-background-select .input-wrapper {
  position: relative;
}

.gooten-components-container .component-background-select .input-wrapper .hex-placeholder {
  position: absolute;
  left: 5px;
  top: 20px;
  opacity: 0;
}

.gooten-components-container .component-background-select .input-wrapper .input-container:focus-within+div {
  opacity: 1;
}

.gooten-components-container .component-background-select .icon-success,
.gooten-components-container .component-background-select .icon-error {
  margin-top: 15px;
}

.gooten-components-container .component-background-select .add-custom-color-btn {
  display: inline-block;
  padding: 3px;
}

.gooten-components-container .component-background-select .add-custom-color-btn div {
  width: 38px;
  height: 38px;
}