.gooten-components-container .tiktok-connect-container {
  padding-top: 40px;
  position: relative;
}

.gooten-components-container .tiktok-connect-container .border-block-img-tiktok-container {
  margin-top: 10px;
  justify-content: center;
  height: 564px;
}

.gooten-components-container .tiktok-connect-container .border-block-img-tiktok-container .border-block-img {
  width: 100%;
  background: url("./assets/image.svg") top center no-repeat;
}

@media screen and (max-width: 479px) {
  .gooten-components-container .tiktok-connect-container .border-block-img-tiktok-container {
    margin-top: 40px;
  }

  .gooten-components-container .tiktok-connect-container .border-block-img-tiktok-container .border-block-img {
    background: url("./assets/image.svg") top left no-repeat;
  }
}