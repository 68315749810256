@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.gooten-components-container .order-panel-layout-container .left-side-container {
  width: calc(100% - 350px);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .gooten-components-container .order-panel-layout-container .left-side-container {
    top: 0;
  }
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container .left-side-container {
    width: 100%;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
}

.gooten-components-container .order-panel-layout-container .left-side-container .left-side-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -50px;
  padding-right: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container .left-side-container .left-side-content {
    padding: 0;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
}

.gooten-components-container .order-panel-layout-container .right-side-container {
  width: 350px;
  background: #f8f8f8;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .gooten-components-container .order-panel-layout-container .right-side-container {
    top: 0;
  }
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container .right-side-container {
    width: 100%;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    margin: 0.5rem 0;
  }
}

@media (max-width: 768px) {
  .gooten-components-container .order-panel-layout-container .right-side-container {
    width: 100%;
    margin: 0;
  }
}

.gooten-components-container .order-panel-layout-container .right-side-container .right-side-content {
  padding: 2.5rem 2rem 6.75rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  border-left: 1px solid #dedede;
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container .right-side-container .right-side-content {
    padding: 1.5rem;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    border: 1px solid #dedede;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .gooten-components-container .order-panel-layout-container .right-side-container .right-side-content {
    border-bottom: none;
  }
}

.gooten-components-container .order-panel-layout-container>.buttons-container.bottom {
  position: absolute;
  width: 350px;
  bottom: 0;
  right: 0;
  padding: 15px 0;
  text-align: center;
  background: #f8f8f8;
  border-left: 1px solid #dedede;
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container>.buttons-container.bottom {
    position: relative;
    width: 100%;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 30px 30px;
    border: none;
    background: none;
  }
}

@media (max-width: 768px) {
  .gooten-components-container .order-panel-layout-container>.buttons-container.bottom {
    margin: 0;
    padding-top: 10px;
    background: #f8f8f8;
    border: 1px solid #dedede;
    border-top: none;
  }
}

.gooten-components-container .order-panel-layout-container>.buttons-container.bottom>button {
  margin: 0 auto;
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container>.buttons-container.bottom>button {
    display: block;
    width: 100%;
  }
}

.gooten-components-container .order-panel-layout-container>.buttons-container.bottom>button.button-primary {
  min-width: 230px;
}

@media (max-width: 992px) {
  .gooten-components-container .order-panel-layout-container>.buttons-container.bottom>button.button-primary {
    float: inherit;
    min-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 480px) {
  .gooten-components-container .order-panel-layout-container>.buttons-container.bottom>button .hidden-xs {
    display: inline-block !important;
  }
}