.gooten-components-container .image-item .image-item-holder {
  border: 1px solid #dedede;
}

.gooten-components-container .image-item .image-item-holder.selected {
  background: #c9e7e4;
  border: 1px solid #c9e7e4;
}

.gooten-components-container .image-item .image-item-holder .image-placeholder {
  background-color: #f2f3f7;
}

.gooten-components-container .image-item .image-item-holder .image-data .image-type {
  background-color: #9d9aa0;
}