.gooten-components-container .country-selection-container {
  background-image: url("assets/Illustration_Globe.png");
  background-size: 962px 673px;
  background-position: right bottom;
  background-repeat: no-repeat;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
  .gooten-components-container .country-selection-container {
    background-image: url("assets/Illustration_Globe@2x.png");
  }
}