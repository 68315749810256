.gooten-components-container .banner-container {
  position: relative;
  background: #fff;
  padding: 24px;
  display: flex;
}

@media (max-width: 480px) {
  .gooten-components-container .banner-container {
    flex-wrap: wrap;
  }
}

.gooten-components-container .banner-container .banner-image {
  width: 160px;
  height: auto;
  margin-right: 10px;
  background: url("./assets/FirstOrder.svg") no-repeat center;
}

@media (max-width: 480px) {
  .gooten-components-container .banner-container .banner-image {
    width: 100%;
    height: 100px;
    margin-right: 0;
  }

  .gooten-components-container .banner-container .banner-image img {
    max-height: 100px;
  }
}

.gooten-components-container .banner-container .banner-text {
  flex-grow: 1;
  padding: 5px;
}

@media (max-width: 480px) {
  .gooten-components-container .banner-container .banner-text {
    width: 100%;
  }
}

.gooten-components-container .banner-container .banner-text h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  padding-bottom: 10px;
}

@media (max-width: 480px) {
  .gooten-components-container .banner-container .banner-text h1 {
    text-align: center;
    margin-top: 10px;
  }
}

.gooten-components-container .banner-container .banner-text h1,
.gooten-components-container .banner-container .banner-text p {
  margin: 0;
}

.gooten-components-container .banner-container .banner-text a {
  color: #422f39;
  border-bottom: 2px solid #62c3b1;
  text-decoration: none;
}

.gooten-components-container .banner-container .close-banner {
  position: absolute;
  right: 0;
  top: 0;
}