.gooten-components-container .product-publish-mockups .images-grid {
  margin: 24px -4px;
}

.gooten-components-container .product-publish-mockups .images-grid .publish-preview-image-item {
  padding: 0 4px;
}

.gooten-components-container .product-publish-mockups .upload-holder .icon-checkbox-selected {
  color: #62c3b1;
}