.gooten-components-container .print-guidelines span.title {
  font-weight: 600;
}

.gooten-components-container .print-guidelines span.learn-more {
  float: right;
  cursor: pointer;
  height: 21px;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .print-guidelines .bleed-line {
  display: block;
  margin-bottom: 10px;
}

.gooten-components-container .print-guidelines .preview {
  display: inline-block;
  height: 3px;
}

.gooten-components-container .print-guidelines .preview.lines {
  margin: 0 0 5px 15px;
  background-image: linear-gradient(90deg, #9d9aa0 53%, rgba(0,0,0,0) 0%);
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  width: 66px;
}

.gooten-components-container .print-guidelines .preview.dots {
  margin: 0 0 5px 42px;
  background-image: linear-gradient(to right, #2c2229 53%, rgba(0,0,0,0) 0%);
  background-position: bottom;
  background-size: 14px 2px;
  background-repeat: repeat-x;
  width: 70px;
}

.gooten-components-container .print-guidelines hr {
  margin-top: 3px;
}