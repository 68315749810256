.gooten-components-container .variant-selection-container img,
.gooten-components-container .variant-selection-container .img-missing-2 {
  border: 1px solid #dedede;
}

.gooten-components-container .variant-selection-container .variants-panel .selected {
  border-color: #9d9aa0;
}

.gooten-components-container .variant-selection-container .variants-panel .selected-item-icon {
  color: #62c3b1;
  background-color: white;
}