.gooten-components-container .dashboard-order-summary-container {
  height: 230px;
  background: #c9e7e4;
  width: 100%;
  margin-bottom: 40px;
  padding: 24px 32px;
}

@media (max-width: 992px) {
  .gooten-components-container .dashboard-order-summary-container {
    margin-top: 16px;
  }
}

.gooten-components-container .dashboard-order-summary-container .order-summary__title {
  font-size: 21px;
  font-family: Avenir-Heavy;
  border-bottom: 1px solid #422f39;
  padding-bottom: 8px;
  margin-top: 0;
  line-height: 32px;
  color: #42303a;
}

.gooten-components-container .dashboard-order-summary-container .orders--month,
.gooten-components-container .dashboard-order-summary-container .orders--attention {
  width: 50%;
  float: left;
  margin-top: 10px;
}

.gooten-components-container .dashboard-order-summary-container .orders--month p,
.gooten-components-container .dashboard-order-summary-container .orders--attention p {
  margin: 0;
}

@media (max-width: 480px) {
  .gooten-components-container .dashboard-order-summary-container .orders--month .order-count__title,
  .gooten-components-container .dashboard-order-summary-container .orders--attention .order-count__title {
    max-width: 120px;
    word-wrap: break-word;
  }
}

.gooten-components-container .dashboard-order-summary-container .orders--month .order-count,
.gooten-components-container .dashboard-order-summary-container .orders--attention .order-count {
  font-family: Avenir-Heavy;
  font-size: 32px;
  color: #422f39;
}

.gooten-components-container .dashboard-order-summary-container .orders--attention .order-count {
  color: #f1643a;
}