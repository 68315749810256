.gooten-components-container .url-link-container a span {
  color: #422f39;
}

.gooten-components-container .url-link-container a {
  cursor: pointer;
  font-size: 15px;
  color: #422f39;
  border-bottom: 2px solid #62c3b1;
  text-decoration: none;
}

.gooten-components-container .url-link-container a:hover {
  color: #422f39;
  text-decoration: none;
}