.gooten-components-container .image-selection-print-space-item .space-title {
  color: #42303a;
}

.gooten-components-container .image-selection-print-space-item .number-of-images {
  background-color: #62c3b1;
}

.gooten-components-container .image-selection-print-space-item .image-placeholder {
  background-image: url("../../../assets/spaces_placeholder.png");
  background-repeat: no-repeat;
  background-position: center;
}

.gooten-components-container .image-selection-print-space-item .image-placeholder.selected {
  background-image: url("../../../assets/spaces_placeholder_selected.png");
}