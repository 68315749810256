.gooten-components-container .text-input-container {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.gooten-components-container .text-input-container label.required:after {
  content: ' *';
}

.gooten-components-container .text-input-container .input-container {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.gooten-components-container .text-input-container .input-container .allow-overlap {
  padding-right: 50px;
}

.gooten-components-container .text-input-container .input-container .chars-over-limit {
  position: absolute;
  height: 26px;
  min-width: 26px;
  top: 11px;
  right: 11px;
  padding: 2px;
  line-height: 24px;
  border: 1px solid #a94442;
  border-radius: 50%;
  text-align: center;
  color: #a94442;
}