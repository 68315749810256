.gooten-components-container .options-container {
  margin-top: -25px;
  padding-right: 15px;
}

.gooten-components-container .options-container .options-line {
  margin-left: 0px;
  margin-bottom: 2rem;
}

.gooten-components-container .options-container .options-line .error-field {
  color: #c80404;
  fill: #c80404;
  position: absolute;
  top: 2rem;
  margin-left: -0.25rem;
}

.gooten-components-container .options-container .options-line .error-color {
  color: #c80404;
}

.gooten-components-container .options-container .options-line h4 {
  margin-bottom: 1.5rem;
}

.gooten-components-container .options-container .options-line select {
  padding-right: 25px;
}

.gooten-components-container .options-container .options-line .option-values {
  margin-left: -6px;
}

.gooten-components-container .options-container .options-line .option-values button {
  margin: 0.25rem;
  float: left;
}

.gooten-components-container .options-container .options-line .checkbox {
  display: block;
  float: left;
  margin-left: 10px;
}

.gooten-components-container .options-container .options-line .checkbox .indicator {
  display: inline-block;
  vertical-align: top;
}

.gooten-components-container .options-container .options-line .colorpill-container+.checkbox {
  margin-top: 13px;
}