.gooten-components-container .image-upload-modal .btn {
  height: 40px;
  font-weight: 900;
  padding-top: 9px;
}

.gooten-components-container .image-upload-modal ul {
  padding-inline-start: 28px;
}

.gooten-components-container .image-upload-modal .modal-footer .btn {
  margin-left: 24px;
  width: 140px;
}

.gooten-components-container .image-upload-modal .image-selection-upload-device {
  height: 146px;
  margin-top: 0px;
}

.gooten-components-container .image-upload-modal .image-selection-upload-device .bcg-image {
  display: none;
}

@media screen and (min-width: 768px) {
  .gooten-components-container .image-upload-modal .right-flex-container>.card:last-child {
    width: 572px;
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 768px) {
  .gooten-components-container .image-upload-modal .modal-body .image-selection-upload-device.flex-container {
    display: inline-block;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .gooten-components-container .image-upload-modal .modal-footer .btn {
    margin-left: inherit;
    width: 100%;
  }

  .gooten-components-container .image-upload-modal .modal-footer .btn:last-child {
    margin-top: 20px;
  }

  .gooten-components-container .image-upload-modal .modal-body .image-selection-upload-device.flex-container {
    width: 100%;
    margin-left: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .gooten-components-container .image-upload-modal .modal-body .image-selection-upload-device.flex-container .btn.btn-primary {
    width: 100%;
  }
}