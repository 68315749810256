.gooten-components-container .sku-selection-container .confirmation-container .confirmation-buttons {
  display: flex;
  justify-content: center;
}

.gooten-components-container .sku-selection-container .confirmation-container .confirmation-buttons .hide-btn-container {
  width: 230px;
}

@media (max-width: 540px) {
  .gooten-components-container .sku-selection-container .confirmation-container .confirmation-buttons .hide-btn-container {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .gooten-components-container .sku-selection-container .confirmation-container .confirmation-buttons {
    display: block;
  }
}