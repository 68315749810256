.gooten-components-container .big-commerce-connect-container {
  padding-top: 40px;
  position: relative;
}

.gooten-components-container .big-commerce-connect-container .border-block-img-big-commerce-container {
  margin-top: 10px;
  justify-content: center;
  height: 564px;
}

.gooten-components-container .big-commerce-connect-container .border-block-img-big-commerce-container .border-block-img {
  width: 100%;
  background: url("./assets/big-commerce-name-hint.png") top center no-repeat;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .big-commerce-connect-container .border-block-img-big-commerce-container {
    margin-top: 40px;
  }

  .gooten-components-container .big-commerce-connect-container .border-block-img-big-commerce-container .border-block-img {
    background: url("./assets/big-commerce-name-hint.png") top left no-repeat;
  }
}