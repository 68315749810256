.gooten-components-container .checkout-container .right-side-content {
  background-color: #f6f7f9;
}

.gooten-components-container .checkout-container .checkout-loader-container {
  position: fixed;
  z-index: 2;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(30,39,49,0.45);
}

.gooten-components-container .checkout-container .cart-items-count {
  display: inline-block;
  color: #9d9aa0;
  font-weight: normal;
}

.gooten-components-container .checkout-container .checkout-accordion {
  margin-left: 20px;
  margin-right: 20px;
}

.gooten-components-container .checkout-container .checkout-accordion .collapse-container .collapse-content-container .collapse-content {
  padding-left: 0px;
}

.gooten-components-container .checkout-container .checkout-accordion .collapse-container .collapse-content-container .collapse-closed {
  padding: 0 0 30px;
}