.gooten-components-container .resource-card-container ol.tree {
  padding: 0 0 0 30px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.gooten-components-container .resource-card-container ol.tree li a {
  margin-left: 15px;
  line-height: 2.27;
  border-bottom: 2px solid #62c3b1;
  text-decoration: none;
}

.gooten-components-container .resource-card-container li label {
  font-size: 17px;
  background: url(assets/triangle.png) 15px 13px no-repeat;
  padding-left: 40px;
  padding-top: 10px;
}

.gooten-components-container .resource-card-container ol.tree li {
  position: relative;
  margin-left: -45px;
  list-style: none;
}

.gooten-components-container .resource-card-container ol.tree li:last-child {
  padding-bottom: 5px;
}

.gooten-components-container .resource-card-container ol.tree li input {
  position: absolute;
  left: 0;
  margin-left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  height: 1em;
  width: 1em;
  top: 1px;
}

.gooten-components-container .resource-card-container li input+ol {
  margin: -0.938em 0 0 -44px;
  height: 1em;
}

.gooten-components-container .resource-card-container li input+ol>li {
  display: none;
  margin-left: -10px !important;
  padding-left: 1px;
}

.gooten-components-container .resource-card-container li input:checked+ol {
  margin: -1.25em 0 0 -44px;
  padding: 1.563em 0 0 80px;
  height: auto;
}

.gooten-components-container .resource-card-container li input:checked+ol>li {
  display: block;
  margin: 0 0 0.125em;
}

.gooten-components-container .resource-card-container li input:checked+ol>li:last-child {
  margin: 0 0 0.063em;
}