.gooten-components-container h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 50px;
  line-height: 1.6;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h2 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h3 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h4,
.gooten-components-container .section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .sub-section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.76;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .bold-content {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: #2c2229;
  letter-spacing: 0px;
}

.gooten-components-container .body-content {
  font-family: "Avenir-Light",sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 15px;
  color: #2c2229;
  text-transform: inherit;
  letter-spacing: 0px;
}

.gooten-components-container .meta-content {
  font-family: "Avenir-Light",sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2229;
}

.gooten-components-container .link-default {
  text-decoration: none;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .link-default:hover,
.gooten-components-container .link-default:active {
  text-decoration: none;
  background-color: #62c3b1;
  color: #42303a;
}

.gooten-components-container .link-default:visited,
.gooten-components-container .link-default:focus {
  text-decoration: none;
}

.gooten-components-container .cart-item-container {
  overflow: hidden;
  clear: both;
}

.gooten-components-container .cart-item-container .product-info-container {
  display: block;
  clear: both;
  margin-top: 26px;
  min-height: 190px;
}

.gooten-components-container .cart-item-container .product-info-container .item-image-section {
  position: absolute;
  width: 124px;
}

@media (max-width: 768px) {
  .gooten-components-container .cart-item-container .product-info-container .item-image-section {
    width: 80px;
  }
}

.gooten-components-container .cart-item-container .product-info-container .item-options {
  margin-left: 146px;
}

@media (max-width: 768px) {
  .gooten-components-container .cart-item-container .product-info-container .item-options {
    margin-left: 102px;
  }
}

.gooten-components-container .cart-item-container .product-info-container .item-options .item-title {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}

.gooten-components-container .cart-item-container .product-info-container .item-options .product-price-xs {
  margin-top: 5px;
  font-weight: 600;
}

.gooten-components-container .cart-item-container .product-info-container .item-options .options {
  margin-top: 10px;
}

.gooten-components-container .cart-item-container .product-info-container .item-options .options .item-option {
  display: block;
  margin-top: 5px;
}

.gooten-components-container .cart-item-container .product-info-container .price-and-quantity {
  height: 36px;
  margin-top: -9px;
}

@media (max-width: 768px) {
  .gooten-components-container .cart-item-container .product-info-container .price-and-quantity {
    margin-top: 20px;
  }
}

.gooten-components-container .cart-item-container .product-info-container .price-and-quantity span {
  padding: 0;
  line-height: 1;
}

.gooten-components-container .cart-item-container .product-info-container .product-price {
  text-align: center;
  margin-top: -1px;
  font-weight: 600;
}

.gooten-components-container .cart-item-container .btn-cart-item-action {
  background: none;
  border: none;
  font-weight: 500;
  line-height: 10px;
  text-transform: none;
  text-align: left;
  padding-left: 8px;
  padding-right: 0;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2px;
}