.gooten-components-container .colorpill-container {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
}

.gooten-components-container .colorpill-container .colorpill {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #dedede;
}

.gooten-components-container .colorpill-container.active,
.gooten-components-container .colorpill-container:hover {
  border-color: #42303a;
}

.gooten-components-container .colorpill-container.disabled .disabled-diagonal-line {
  display: block;
}

.gooten-components-container .colorpill-container .disabled-diagonal-line {
  display: none;
  position: absolute;
  left: 2px;
  top: 0;
  cursor: not-allowed;
  background: url("./assets/cross-out-colors.svg") no-repeat center;
  width: 40px;
  height: 40px;
}

.gooten-components-container .colorpill-container.two-color-item .colorpill {
  border: none;
}

.gooten-components-container .colorpill-container.two-color-item .colorpill .color-one,
.gooten-components-container .colorpill-container.two-color-item .colorpill .color-two {
  content: '';
  height: 40px;
  width: 20px;
}

.gooten-components-container .colorpill-container.two-color-item .colorpill .color-one {
  float: left;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.gooten-components-container .colorpill-container.two-color-item .colorpill .color-two {
  float: right;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}