.gooten-components-container .side-image.etsy {
  background-image: url("./assets/etsy.png");
}

.gooten-components-container .side-image.shopify {
  background-image: url("./assets/shopify.png");
}

.gooten-components-container .side-image.woocommerce {
  background-image: url("./assets/woo.png");
}

.gooten-components-container .side-image.bigcommerce {
  background: url("./assets/bigcommerce.png") center/cover;
}

.gooten-components-container .input-group {
  display: flex;
}

.gooten-components-container .input-group-addon {
  border-radius: 0 4px 4px 0;
  background-color: #f2f3f7;
  width: auto;
  height: 3rem;
  align-items: center;
  margin-left: -0.25rem;
  display: flex;
}