.gooten-components-container .image-thumb {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.gooten-components-container .image-thumb.loading,
.gooten-components-container .image-thumb.error {
  cursor: not-allowed;
}

.gooten-components-container .image-thumb .image-details-actions-container {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.gooten-components-container .image-thumb .image-details-actions-container span {
  background-color: #9d9aa0;
  color: #fff;
  font-size: 22px;
  line-height: 22px;
}

.gooten-components-container .image-thumb .image-details-actions-container .remove-image-btn {
  display: none;
  text-transform: none;
  margin-left: -10px;
  padding-left: 0px;
  padding-right: 0px;
  color: #f1643a;
  background-color: #fff;
  width: 160px;
  height: 42px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5) !important;
  line-height: 12px;
  border: none;
}

.gooten-components-container .image-thumb .image-details-actions-container .remove-image-btn.active {
  display: block;
}

.gooten-components-container .image-thumb .image-details-actions-container:hover .remove-image-btn {
  display: block;
}

.gooten-components-container .image-thumb .thumb-container {
  border: 1px solid #dedede;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.gooten-components-container .image-thumb .thumb-container .img-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gooten-components-container .image-thumb .thumb-container .img-wrap .responsive-container {
  display: block;
  width: 100%;
  height: 100%;
}

.gooten-components-container .image-thumb .thumb-container .img-wrap .responsive-container .img-responsive {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.gooten-components-container .image-thumb .thumb-container .img-wrap .responsive-container .img-responsive.image-missing {
  background: url("./assets/image-missing.png") center center/contain no-repeat;
}

.gooten-components-container .image-thumb .thumb-container .img-wrap .responsive-container .img-responsive.image-error {
  background: url("./assets/image-error.png") center center/contain no-repeat;
}

.gooten-components-container .image-thumb .caption {
  background-color: rgba(0,0,0,0.7);
  bottom: 20px;
  color: #fff;
  font-size: 10px;
  padding: 3px 3px 3px 4px;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.gooten-components-container .image-thumb .title {
  margin-top: 10px;
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
  position: absolute;
}

.gooten-components-container .image-thumb.active .img-wrap {
  outline: 2px solid #62c3b1;
}

.gooten-components-container .image-thumb.active .icon-checkmark {
  background-color: #62c3b1;
  color: #fff;
  font-size: 10px;
  margin: 0;
  padding: 7px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}