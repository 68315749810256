.gooten-components-container .print-guidelines-content {
  padding: 0 30px 0 30px;
}

.gooten-components-container .print-guidelines-content hr {
  width: 100%;
  display: flex;
}

.gooten-components-container .print-guidelines-content .subtitle {
  display: block;
  font-weight: 600;
  font-size: 15px;
}

.gooten-components-container .print-guidelines-content .content {
  padding: 10px 0 14px 16px;
  float: left;
  width: 100%;
}

.gooten-components-container .print-guidelines-content .content a {
  cursor: pointer;
  color: black;
  border-bottom: 2px solid #62c3b1;
  text-decoration: none;
}

.gooten-components-container .print-guidelines-content .image-bleed {
  height: 200px;
  margin: 44px auto 0;
  margin-bottom: -10px;
  display: block;
  background: url("./assets/bleed.png") no-repeat left;
  background-size: contain;
}

.gooten-components-container .print-guidelines-content .inner-subtitle {
  display: block;
  font-size: 13px;
  font-weight: 900;
  padding: 14px 0 0 16px;
}