.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container {
  outline: none;
  margin: 0 -0.5rem;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .filter-item input {
  width: 100%;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .action-item {
  cursor: pointer;
  padding: 10px 10px;
  position: relative;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu {
  border: none;
  box-shadow: none;
  padding: 0 0.5rem;
  display: block;
  overflow-y: auto;
  max-height: 20rem;
  width: 100%;
  position: relative;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item {
  cursor: pointer;
  padding: 10px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item .item-image {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  border-radius: 50%;
  background-color: #dedede;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item .select-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-right: 20px;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item .select-item-label.with-wrap {
  white-space: pre-wrap;
  text-overflow: initial;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item .select-item-label.has-image {
  margin-left: 46px;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item {
  border-bottom: 1px solid #d0eae7;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item i {
  padding-left: 10px;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item.selected {
  background-color: #f5f5f5;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item .select-item.disabled {
  font-weight: 900;
  background-color: #f8f8f8;
  cursor: default;
}

.gooten-components-container .multiselect-component.dropdown .dropdown-menu-container .dropdown-menu .dropdown-item:last-child .select-item {
  border-bottom: none;
}