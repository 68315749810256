.gooten-components-container .link-product-selection-view-container .connect-btn.disabled {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .link-product-selection-view-container .container-mobile {
    padding-left: 0px;
    padding-right: 0px;
  }
}