.gooten-components-container .component-side-bar {
  z-index: 105;
}

.gooten-components-container .component-side-bar .side-bar-container {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 340px;
  height: 100%;
  float: right;
  padding: 10px;
  overflow-y: scroll;
  box-shadow: -1px 0px 2px 0 #dedede;
  z-index: 200;
}

.gooten-components-container .component-side-bar .side-bar-container.panel-width {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .gooten-components-container .component-side-bar .side-bar-container.panel-width {
    width: 100%;
  }
}

.gooten-components-container .component-side-bar .side-bar-content {
  padding: 0 10px 10px 20px;
}

.gooten-components-container .component-side-bar .base-text-style {
  text-align: left;
}

.gooten-components-container .component-side-bar .side-bar-transitions-enter {
  right: -340px;
}

.gooten-components-container .component-side-bar .side-bar-panel-width-transitions-enter {
  right: -50%;
}

@media screen and (max-width: 992px) {
  .gooten-components-container .component-side-bar .side-bar-panel-width-transitions-enter {
    right: -100%;
  }
}

.gooten-components-container .component-side-bar .side-bar-transitions-enter.side-bar-transitions-enter-active,
.gooten-components-container .component-side-bar .side-bar-panel-width-transitions-enter.side-bar-panel-width-transitions-enter-active {
  right: 0;
  transition: 700ms;
}

.gooten-components-container .component-side-bar .side-bar-transitions-leave,
.gooten-components-container .component-side-bar .side-bar-panel-width-transitions-leave {
  right: 0;
}

.gooten-components-container .component-side-bar .side-bar-transitions-leave.side-bar-transitions-leave-active {
  right: -340px;
  transition: 700ms;
}

.gooten-components-container .component-side-bar .side-bar-panel-width-transitions-leave.side-bar-panel-width-transitions-leave-active {
  right: -50%;
  transition: 700ms;
}

@media screen and (max-width: 992px) {
  .gooten-components-container .component-side-bar .side-bar-panel-width-transitions-leave.side-bar-panel-width-transitions-leave-active {
    right: -100%;
    transition: 700ms;
  }
}

.gooten-components-container .component-side-bar .side-bar-get-started-button-container {
  margin-right: 10px;
  margin-top: 20px;
}

.gooten-components-container .component-side-bar .sb-content {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .gooten-components-container .component-side-bar .side-bar-container {
    width: 100%;
  }

  .gooten-components-container .component-side-bar .side-bar-transitions-enter {
    right: -100%;
  }

  .gooten-components-container .component-side-bar .side-bar-transitions-leave.side-bar-transitions-leave-active {
    right: -100%;
    transition: 700ms;
  }
}