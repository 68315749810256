.gooten-components-container .store-storage-empty-landing-container {
  margin-top: 38px;
}

.gooten-components-container .store-storage-empty-landing-container h3 {
  color: #42303a;
}

.gooten-components-container .store-storage-empty-landing-container p {
  margin-top: 20px;
  color: #9d9aa0;
}

.gooten-components-container .store-storage-empty-landing-container p a {
  color: inherit;
}

.gooten-components-container .store-storage-empty-landing-container .landing-image.img-storage {
  background: url("./assets/storage-illustration.svg") center center/contain no-repeat;
  height: 242px;
}