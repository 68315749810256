.gooten-components-container h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 50px;
  line-height: 1.6;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h2 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h3 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h4,
.gooten-components-container .section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .sub-section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.76;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .bold-content {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: #2c2229;
  letter-spacing: 0px;
}

.gooten-components-container .body-content {
  font-family: "Avenir-Light",sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 15px;
  color: #2c2229;
  text-transform: inherit;
  letter-spacing: 0px;
}

.gooten-components-container .meta-content {
  font-family: "Avenir-Light",sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2229;
}

.gooten-components-container .link-default {
  text-decoration: none;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .link-default:hover,
.gooten-components-container .link-default:active {
  text-decoration: none;
  background-color: #62c3b1;
  color: #42303a;
}

.gooten-components-container .link-default:visited,
.gooten-components-container .link-default:focus {
  text-decoration: none;
}

.gooten-components-container .country-selector-inline-container .country-selector-inline-body {
  background-color: #d0eae7;
  padding: 14px 40px;
}

.gooten-components-container .country-selector-inline-container .country-selector-inline-body .top-row {
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.gooten-components-container .country-selector-inline-container .country-selector-inline-body .bottom-row {
  margin-bottom: 6px;
}

.gooten-components-container .country-selector-inline-container .country-selector-inline-body .bottom-row .dropdown {
  width: 290px;
}

.gooten-components-container .country-selector-inline-container .slide-down-enter {
  max-height: 0;
  overflow: hidden;
}

.gooten-components-container .country-selector-inline-container .slide-down-enter.slide-down-enter-active {
  max-height: 700px;
  overflow: hidden;
  transition: max-height 300ms ease-in;
}

.gooten-components-container .country-selector-inline-container .slide-down-leave {
  max-height: 700px;
  overflow: hidden;
}

.gooten-components-container .country-selector-inline-container .slide-down-leave.slide-down-leave-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}