.gooten-components-container .component-color-preset {
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  background-clip: content-box;
  border: 1px solid transparent;
  border-radius: 4px;
}

.gooten-components-container .component-color-preset div {
  width: 38px;
  height: 38px;
  border-radius: 2px;
}

.gooten-components-container .component-color-preset .icon-checkmark {
  display: none;
  position: absolute;
  font-size: 24px;
  padding: 8px 8px;
  color: #fccf2b;
}

.gooten-components-container .component-color-preset.selected {
  border-color: #42303a;
}

.gooten-components-container .component-color-preset.selected .icon-checkmark {
  display: block;
}

.gooten-components-container .component-color-preset.disabled {
  cursor: not-allowed;
}