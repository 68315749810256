.gooten-components-container h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 50px;
  line-height: 1.6;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h2 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h3 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h4,
.gooten-components-container .section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .sub-section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.76;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .bold-content {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: #2c2229;
  letter-spacing: 0px;
}

.gooten-components-container .body-content {
  font-family: "Avenir-Light",sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 15px;
  color: #2c2229;
  text-transform: inherit;
  letter-spacing: 0px;
}

.gooten-components-container .meta-content {
  font-family: "Avenir-Light",sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2229;
}

.gooten-components-container .link-default {
  text-decoration: none;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .link-default:hover,
.gooten-components-container .link-default:active {
  text-decoration: none;
  background-color: #62c3b1;
  color: #42303a;
}

.gooten-components-container .link-default:visited,
.gooten-components-container .link-default:focus {
  text-decoration: none;
}

.gooten-components-container .main-panel-container {
  padding: 30px;
}

.gooten-components-container .main-panel-container.margin {
  margin-bottom: 60px;
  margin-right: 18px;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .main-panel-container.margin {
    margin-bottom: 115px;
  }
}

.gooten-components-container .main-panel-container h2 {
  padding-bottom: 10px;
}

.gooten-components-container .main-panel-container .separator-content {
  display: inline-block;
  width: 100%;
  margin-left: 6px;
  margin-bottom: 18px;
}

.gooten-components-container .main-panel-container .separator-content hr {
  width: 100%;
  float: left;
  margin-top: 7px;
}

.gooten-components-container .main-panel-container .separator-content span {
  float: left;
  margin-top: -13px;
  color: #9d9aa0;
  font-size: 12px;
}

.gooten-components-container .main-panel-container .number-of-selected-items {
  float: right;
  padding: 3px 8px 0 8px;
  border-radius: 14px;
  background-color: #f48766;
  color: white;
  font-weight: 600;
  font-size: 13px;
  margin-top: -32px;
}

.gooten-components-container .main-panel-container .product-preview-select-all {
  margin: 36px 0 12px 15px;
}

.gooten-components-container .main-panel-container .empty-page {
  background-image: url("assets/empty_page.png");
  background-size: 300px 309px;
  background-position: left top;
  background-repeat: no-repeat;
  width: 300px;
  height: 309px;
  background-position: center;
  width: 100%;
  margin-top: 80px;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
  .gooten-components-container .main-panel-container .empty-page {
    background-image: url("assets/empty_page@2x.png");
  }
}

.gooten-components-container .main-panel-container .link-button {
  text-align: right;
  margin: 4rem 0.5rem 1rem 0;
}

.gooten-components-container .main-panel-container .generated-preview {
  width: 14vw;
  height: 14vw;
}

@media (max-width: 991px) {
  .gooten-components-container .main-panel-container .generated-preview {
    width: 20vw;
    height: 20vw;
  }
}

@media (max-width: 767px) {
  .gooten-components-container .main-panel-container .generated-preview {
    width: 38vw;
    height: 38vw;
  }
}