.gooten-components-container .image-selection-print-spaces-mobile .spaces-mobile-content {
  background-color: #f2f3f7;
}

.gooten-components-container .image-selection-print-spaces-mobile .spaces-mobile-content .title-holder span {
  color: #9d9aa0;
}

.gooten-components-container .image-selection-print-spaces-mobile .spaces-mobile-content .title-holder .btn-close {
  background: url("../../../assets/icon_image_modal_close.png") center center/contain no-repeat;
}