.gooten-components-container .store-connect-container {
  max-width: 700px;
  color: #422f39;
  padding-left: 15px;
  padding-right: 15px;
}

.gooten-components-container .store-connect-container .card {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2em;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.gooten-components-container .store-connect-container .card .card-content {
  margin: 0.5em 0.5em 0 0.5em;
  width: 100%;
}

.gooten-components-container .store-connect-container .card .card-content .card-title {
  font-family: Avenir-Heavy;
  font-size: 21px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #42303a;
}

.gooten-components-container .store-connect-container .card .card-content .card-list-container {
  border-top: 2px solid #eaeaea;
  padding-top: 0.75em;
  margin-top: 0.5em;
  width: 100%;
}

.gooten-components-container .store-connect-container .card .card-content .card-hr-line {
  height: 3px;
  border: solid 1px #dedede;
}

.gooten-components-container .store-connect-container h3.store-connect-title {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 24px;
}

.gooten-components-container .store-connect-container .text-input-container .input-container .chars-over-limit {
  top: 7px;
}

.gooten-components-container .store-connect-container .btn.btn-disabled {
  background-color: #f2f3f7;
  color: #9d9aa0;
  border-color: #f2f3f7;
  opacity: 1;
}

.gooten-components-container .store-connect-container .input-group {
  display: flex;
}

.gooten-components-container .store-connect-container .input-group-addon {
  background-color: #f2f3f7;
  border-radius: 0 4px 4px 0;
  background-color: #f2f3f7;
  width: auto;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-left: -0.25rem;
}

.gooten-components-container .store-connect-container p {
  font-size: 15px;
  line-height: 1.33;
  color: #42303a;
}

.gooten-components-container .store-connect-container .flex-container-row,
.gooten-components-container .store-connect-container .flex-container-column {
  display: flex;
  flex-direction: column;
}

.gooten-components-container .store-connect-container .flex-container-row {
  width: 100%;
}

.gooten-components-container .store-connect-container .flex-container-column {
  flex-wrap: nowrap;
  width: 100%;
}

.gooten-components-container .store-connect-container .text-input-container .input-container .allow-overlap {
  padding-left: 3px;
  padding-right: 3px;
}

.gooten-components-container .store-connect-container .trademark,
.gooten-components-container .store-connect-container .trademark-small {
  font-size: 12px;
  margin-bottom: 30px;
}

.gooten-components-container .store-connect-container .store-connect-form-small,
.gooten-components-container .store-connect-container .trademark-small,
.gooten-components-container .store-connect-container .store-btn-small {
  max-width: 430px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.gooten-components-container .store-connect-container .store-connect-description,
.gooten-components-container .store-connect-container .store-connect-form,
.gooten-components-container .store-connect-container .trademark {
  width: 100%;
  padding: 0;
  margin: 0;
}

.gooten-components-container .store-connect-container .notification-wrapper {
  width: 100%;
  position: absolute;
  margin-top: -40px;
}

.gooten-components-container .store-connect-container .notification-wrapper .notifications-container {
  top: 0px;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .gooten-components-container .store-connect-container .notifications-container {
    width: 572px;
  }
}