.gooten-components-container .order-from-view-container .img-responsive.img-create-new {
  background: url("./assets/create-new.svg") center center/contain no-repeat;
}

.gooten-components-container .order-from-view-container .img-responsive.img-past-orders {
  background: url("./assets/past-orders.svg") center center/contain no-repeat;
}

.gooten-components-container .order-from-view-container .img-responsive.img-hub-products {
  background: url("./assets/hub-products.svg") center center/contain no-repeat;
}