.gooten-components-container h1 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 50px;
  line-height: 1.6;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h2 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h3 {
  font-family: "Queulat-Condensed-Alt-Regular",sans-serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container h4,
.gooten-components-container .section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .sub-section-header {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.76;
  text-align: left;
  color: #2c2229;
}

.gooten-components-container .bold-content {
  font-family: "AvenirLTStd-Medium",sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: #2c2229;
  letter-spacing: 0px;
}

.gooten-components-container .body-content {
  font-family: "Avenir-Light",sans-serif;
  line-height: 1.6;
  text-align: left;
  font-size: 15px;
  color: #2c2229;
  text-transform: inherit;
  letter-spacing: 0px;
}

.gooten-components-container .meta-content {
  font-family: "Avenir-Light",sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #2c2229;
}

.gooten-components-container .link-default {
  text-decoration: none;
  border-bottom: 2px solid #62c3b1;
}

.gooten-components-container .link-default:hover,
.gooten-components-container .link-default:active {
  text-decoration: none;
  background-color: #62c3b1;
  color: #42303a;
}

.gooten-components-container .link-default:visited,
.gooten-components-container .link-default:focus {
  text-decoration: none;
}

.gooten-components-container .component-product-preview .modal-dialog {
  top: 100px;
}

.gooten-components-container .component-product-preview .main-container {
  width: 70%;
}

.gooten-components-container .component-product-preview .panel-container {
  width: 30%;
}

@media screen and (max-width: 991px) {
  .gooten-components-container .component-product-preview .panel-container {
    width: 90%;
  }
}

.gooten-components-container .component-product-preview .nav-buttons-container {
  text-align: center;
  position: absolute;
  bottom: 0px;
  right: 54px;
  width: 450px;
  background: rgba(255,255,255,0.66);
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .component-product-preview .nav-buttons-container {
    right: 0px;
    width: 100%;
    height: 70px;
  }
}

.gooten-components-container .component-product-preview .nav-buttons-container .cancel-button .btn.btn-default-outline {
  width: 150px;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .component-product-preview .nav-buttons-container .cancel-button .btn.btn-default-outline {
    width: 20vw;
    margin: -8px 0 0 -18px;
  }
}

.gooten-components-container .component-product-preview .nav-buttons-container .button-cta {
  margin: 20px;
  width: 280px;
  float: none;
}

@media screen and (max-width: 767px) {
  .gooten-components-container .component-product-preview .nav-buttons-container .button-cta {
    width: 60%;
    margin-top: 10px;
  }
}

.gooten-components-container .component-product-preview.pro-preview-mode .panel-layout-container .buttons-container .btn.btn-primary {
  background: #f1643a;
  border: 1px solid #f1643a;
}