.gooten-components-container .component-close-button {
  float: left;
  border: none;
  display: inline-block;
  background-color: #fccf2b;
  width: 30px;
  height: 30px;
  font-size: 24px;
  padding: 0 4px;
  text-align: center;
  margin: -10px;
  line-height: 10px;
}

.gooten-components-container .component-close-button:hover {
  opacity: 0.8;
}