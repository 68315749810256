.gooten-components-container .cart-container .preview-image-modal-body {
  margin-bottom: 30px;
}

.gooten-components-container .cart-container .cart-title {
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 8px;
  display: inline-block;
}

.gooten-components-container .cart-container .cart-items-count {
  display: inline-block;
  color: #9d9aa0;
  font-weight: normal;
}

.gooten-components-container .cart-container .btn-keep-shopping {
  margin: 26px 30px 20px 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .gooten-components-container .cart-container .btn-keep-shopping {
    margin-bottom: 40px;
  }
}

.gooten-components-container .cart-container .btn-keep-shopping>span {
  font-weight: 900;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 38px;
  border-bottom: 4px solid #62c3b1;
}

.gooten-components-container .cart-container .btn-keep-shopping .cart-item-lookalike {
  width: 124px;
  height: 124px;
  border: 1px solid #dedede;
  font-size: 24px;
}

@media (max-width: 768px) {
  .gooten-components-container .cart-container .btn-keep-shopping .cart-item-lookalike {
    width: 80px;
    height: 80px;
  }
}

.gooten-components-container .cart-container .btn-keep-shopping .cart-item-lookalike .icon-add {
  color: #62c3b1;
}

.gooten-components-container .cart-container .empty-cart {
  flex-direction: column;
  margin-bottom: 20px;
}

.gooten-components-container .cart-container .empty-cart .background-image {
  background-image: url("assets/Illustration_Cart_Empty.png");
  background-size: 293px 263px;
  background-position: left top;
  background-repeat: no-repeat;
  width: 293px;
  height: 263px;
  margin-top: 8px;
  margin-bottom: 70px;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
  .gooten-components-container .cart-container .empty-cart .background-image {
    background-image: url("assets/Illustration_Cart_Empty@2x.png");
  }
}

.gooten-components-container .cart-container .empty-cart .btn-empty-cart-add-products {
  padding-left: 50px;
  padding-right: 50px;
}