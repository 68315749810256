.gooten-components-container .product-panel-container {
  margin-top: 30px;
}

.gooten-components-container .product-panel-container .top-buffer {
  margin-top: 20px;
}

.gooten-components-container .product-panel-container .product-panel-image {
  height: 90%;
  max-width: 90%;
  max-height: 90%;
}